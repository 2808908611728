import React from "react";
import Layout from "components/Layout";
import { graphql } from "gatsby";
import ScoreItem from "components/ScoreItem";

const Component = props => {
  const {
    data: {
      allContentfulScore: { edges: scores }
    }
  } = props;
  return (
    <Layout>
      <h1>Noteneditionen</h1>
      {scores.map(({ node: score }) => (
        <ScoreItem score={score} key={score.id} />
      ))}
    </Layout>
  );
};

export default Component;

export const Posts = graphql`
  query Scores {
    allContentfulScore {
      edges {
        node {
          id
          title
          slug
          subTitle
          composer
          publishedAt
          url
          image {
            id
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            id
            description
            childMarkdownRemark {
              id
              html
            }
          }
        }
      }
    }
  }
`;
