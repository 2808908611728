import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Img from "gatsby-image";

const ScoreItem = props => {
  const { score } = props;
  return (
    <Item>
      <Preview>
        <Img fluid={score.image.fluid} />
      </Preview>
      <Content>
        <Title>{score.title}</Title>
        <SubTitle>{score.subTitle}</SubTitle>
        {score.description ? (
          <Description
            dangerouslySetInnerHTML={{
              __html: score.description.childMarkdownRemark.html
            }}
          />
        ) : null}
      </Content>
    </Item>
  );
};

ScoreItem.propTypes = {
  score: PropTypes.object.isRequired
};

export default ScoreItem;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px #d4d4d5;
  margin: 0 0 1rem 0;
  padding: 1rem;
`;
const Preview = styled.div`
  //flex-basis: 20%;
  width: 25%;
`;
const Content = styled.div`
  margin: 0 0 0 1rem;
  display: flex;
  flex-flow: nowrap column;
  width: 75%;
`;
const Title = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;
const SubTitle = styled.div`
  margin-bottom: 1rem;
`;
const Description = styled.div``;
